import styled, { css } from 'styled-components';
import React, { useRef, useState } from 'react';
import { AccordionMono, Carousel, BlockText, LabelText } from 'src/components/atom';
import { DevicesTab, ModalGroup } from 'src/components/organism';
import { CustomImage, IconSmartApp, Button, Modal } from '@veneer/core';
import { getProps, getSupportedLang, t } from 'src/utils/commonMethods';
import { URL } from 'src/utils/constants';
import { refined, refinedArray } from 'src/utils/refinedMethods';
import { useShellRootContext } from 'src/contexts/ShellRoot';

export type TModalRef = {
  openModal: (string) => void;
};

const Overview = (props: { data }) => {
  const { language, country } = getProps();
  const { featureFlags } = useShellRootContext();
  const currentLocale = `${language}_${country.toUpperCase()}`;
  const modalRef = useRef<TModalRef>();
  const portalRef = useRef<HTMLDivElement>(null);
  const [openDownloadAppConfigModal, setOpenDownloadAppConfigModal] = useState(false);

  const selectedLocaleExists = (list) => {
    return list.length > 0;
  };

  const getLocaleInfo = (localeInfoList) => {
    if (!localeInfoList) {
      return {};
    }

    if (localeInfoList.length === 0) {
      return {};
    }

    let enUS_idx = 0;
    const filteredLocalInfo = localeInfoList.filter((item, idx) => {
      if (item.locale === 'en_US') {
        enUS_idx = idx;
      }
      return item.locale === currentLocale;
    });

    if (selectedLocaleExists(filteredLocalInfo)) {
      return filteredLocalInfo[0];
    }

    return localeInfoList[enUS_idx];
  };

  const getIsFree = () => {
    if (!props.data) {
      return '--';
    }
    return props.data?.isFree ? t('overview.free') : t('overview.licensed');
  };

  const handleCancel = () => {
    setOpenDownloadAppConfigModal(false);
  };

  const handleClose = () => {
    setOpenDownloadAppConfigModal(false);
    portalRef.current.style.display = 'block';
  };

  const handleHide = () => {
    portalRef.current.style.display = 'none';
  };

  return (
    <Wrapper data-testid={'overview-wrapper'}>
      <AccordionMono id={'apps-overview-app-info'} title={t('overview.title')}>
        <Content>
          <InnerBlock>
            <LeftFrame>
              <Title>
                {props.data?.icon ? (
                  <CustomImage
                    src={refined(props.data?.icon)}
                    customStyle={{
                      width: '66px',
                      height: '66px',
                      background: 'lightgray 50% / cover no-repeat',
                    }}
                  />
                ) : (
                  <IconSmartApp size={64} />
                )}

                <LabelWrapper>
                  <Label>{refined(props.data?.title)}</Label>
                  <Wrapper>
                    <SmallLabel data-testid={'overview-small-label-hpinc'}>
                      {t('overview.hpInc')}
                    </SmallLabel>
                    <Anchor href={props.data?.supportLink}>{t('overview.support')}</Anchor>
                  </Wrapper>
                </LabelWrapper>
              </Title>
              <SmallLabel>{getIsFree()}</SmallLabel>
              <BlockGroup>
                <BlockText title={t('overview.platform_type')} val={props.data?.platformType} />
                <Line />
                <BlockText title={t('overview.req_platform')} val={props.data?.platformVersion} />
                <Line />
                <BlockText title={t('overview.size')} val={props.data?.appSize} />
              </BlockGroup>
              <BlockText
                title={t('overview.desc')}
                val={getLocaleInfo(props.data?.localeInfoList)?.description}
              />
            </LeftFrame>

            <RightFrame>
              <Carousel
                data={refinedArray(getLocaleInfo(props.data?.localeInfoList)?.screenshotList)}
              />
            </RightFrame>
          </InnerBlock>
        </Content>
      </AccordionMono>

      {featureFlags.enableDeviceDownloadAppConfig && (
        <>
          <AccordionMono
            id={'apps-overview-download'}
            title={t('overview.download_app_configuration')}
          >
            <Content type="additional">
              <LabelText
                label={t('overview.app_configuration_description', { appName: props.data?.title })}
                detail={
                  <Button
                    appearance="secondary"
                    onClick={() => setOpenDownloadAppConfigModal(true)}
                    data-testid={'overview-download-app-config-btn'}
                    customStyle={{ marginTop: '16px' }}
                  >
                    {t('overview.download_app_configuration')}
                  </Button>
                }
              />
            </Content>
          </AccordionMono>
          <>
            <PortalContainer ref={portalRef} data-testid={'download-app-config-devices-modal'} />
            {openDownloadAppConfigModal && (
              <Modal
                show={openDownloadAppConfigModal}
                onClose={() => setOpenDownloadAppConfigModal(false)}
                expanded={true}
                maxWidth={'1400px'}
                portal={true}
                portalContainer={portalRef.current}
                data-testid={'download-app-config-overview-modal'}
              >
                <TitleArea>{t('overview.download_app_configuration')}</TitleArea>
                <BodyArea>
                  <AppName>
                    {t('overview.app_configuration_app_title', { appName: props.data?.title })}
                  </AppName>
                  <Description>
                    {t('overview.app_configuration_download_description')}{' '}
                    <b>{t('overview.app_configuration_download')}</b>
                  </Description>
                  <DevicesTab
                    appName={props.data?.title}
                    hideModal={handleHide}
                    closeModal={handleClose}
                    data-testid={'download-app-config-overview-devices-tab'}
                  />
                </BodyArea>
                <FooterArea>
                  <ClosePanel>
                    <Button
                      data-testid={'download-app-config-overview-cancel-btn'}
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                    <Button appearance="secondary" disabled={true}>
                      Download
                    </Button>
                  </ClosePanel>
                </FooterArea>
              </Modal>
            )}
          </>
        </>
      )}

      <AccordionMono id={'apps-overview-additional-info'} title={t('overview.title_add_info')}>
        <Content type="additional">
          <LabelText label={t('overview.lang_supported')} detail={getSupportedLang(props.data)} />
          <LabelText
            label={t('overview.permissions')}
            detail={
              <Button
                appearance="ghost"
                onClick={() => modalRef.current.openModal('permission')}
                data-testid={'overview-permission-btn'}
              >
                {t('overview.detail')}
              </Button>
            }
          />
          <LabelText label={t('overview.simp_app_id')} detail={props.data?.simplifiedAppID} />
          <LabelText
            label={t('overview.support')}
            detail={<Anchor href={props.data?.supportLink}>Support</Anchor>}
          />
          <LabelText
            label={t('overview.terms_cond')}
            detail={
              <TermsAndConditions>
                <Anchor href={URL.HP_Privacy_Statement}>{t('overview.privacy_stat')}</Anchor>
                <Anchor href={URL.End_User_License_Agreement}>
                  {t('overview.license_agreement')}
                </Anchor>
              </TermsAndConditions>
            }
          />
        </Content>
      </AccordionMono>

      <ModalGroup ref={modalRef} data={props.data} />
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  padding: 20px;
  gap: 10px;

  ${(props) =>
    props.type == 'additional' &&
    css`
      padding: 16px 24px;
      gap: 16px;
    `}
`;

const InnerBlock = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
`;

const LeftFrame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex: 1 0 0;
`;

const RightFrame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const Title = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
`;

const Label = styled.span`
  color: var(--light-foreground, #212121);

  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const SmallLabel = styled.span`
  color: var(--light-foreground, #212121);

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  margin-right: 20px;
`;

const BlockGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Line = styled.div`
  width: 2px;
  height: 48px;
  margin: 0 12px;
  background: #dbdbdb;
`;

const Anchor = styled.a.attrs(() => ({
  target: '_blank',
}))`
  color: var(--light-primary, #0278ab);
`;

const TermsAndConditions = styled.div`
  display: flex;
  justify-content: space-between;
  width: 380px;
`;

const PortalContainer = styled.div`
  > div > div > div {
    padding: unset !important;
  }
`;

const TitleArea = styled.span`
  display: flex;
  background: #ffffff;
  padding: 15px 24px 16px 24px;
  align-items: center;
  box-sizing: border-box;
  border-radius: 10px;

  width: 100%;
  height: 99px;

  font-family: Forma DJR UI;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
`;

const BodyArea = styled.div`
  background: #f8f8f8;
  padding: 30px 29px 21px 24px;
  height: 594px;
`;

const AppName = styled.div`
  width: 1347px;
  height: 32px;

  font-family: Forma DJR UI;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
  margin-bottom: 8px;
`;

const Description = styled.div`
  height: 24px;
  margin-bottom: 24px;
  gap: 0px;

  font-family: Forma DJR UI;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #212121;
`;

const FooterArea = styled.div`
  min-height: 86px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 37px 0 24px;
  border-top: 1px solid rgba(33, 33, 33, 0.1);
  background: #f8f8f8;

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

const ClosePanel = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
export default Overview;
